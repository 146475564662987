'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';

import AccessibilityText from '@components/AccessibilityText';
import ContactChannels from './contact-channels';

import { useLogout } from '@lib/hooks';
import { getTranslations } from 'app/lib/utils.translations';
import { EXTERNAL_LINKS } from 'app/constants';
import { SiteLocale } from '@types';
import styles from './navigation.module.css';

interface Props {
  locale: SiteLocale;
}

export default function Navigation({ locale }: Props) {
  const t = getTranslations(locale);
  const [isOpen, setIsOpen] = useState(false);

  const closeOverlay = () => setIsOpen(false);

  // const logout = useLogout();
  // const handleLogOut = () => logout(closeOverlay);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  useEffect(() => {
    const onOutsideClick = (event: MouseEvent) => {
      if (isOpen) {
        const element = document.getElementById('mobile-menu');
        const topNavBar = document.getElementById('nav-bar');

        const isOutsideClick =
          event.target !== element &&
          event.target !== topNavBar &&
          !element?.contains(event.target as Node) &&
          !topNavBar?.contains(event.target as Node);

        if (isOutsideClick) {
          closeOverlay();
        }
      }
    };

    window.addEventListener('click', onOutsideClick);

    return () => {
      window.removeEventListener('click', onOutsideClick);
    };
  }, [isOpen]);

  const navItems = [
    {
      key: 'home',
      href: '/',
    },
    {
      key: 'recommendation_engine',
      href: '/recommendation?reset',
    },

    {
      key: 'marketing_site',
      href: EXTERNAL_LINKS.MARKETING_SITE[locale],
      target: '_blank',
    },
  ].map(({ key, href, ...rest }) => (
    <li key={key}>
      <Link
        href={href}
        onClick={closeOverlay}
        aria-current="false"
        className={styles.nav_item}
        {...rest}
      >
        {t(`nav.${key}`)}
      </Link>
    </li>
  ));
  // .concat(
  //   <li key="auth">
  //     {isLoggedIn ? (
  //       <button onClick={handleLogOut} className={styles.nav_item}>
  //         {t('nav.logout')}
  //       </button>
  //     ) : (
  //       <Link
  //         href="/"
  //         onClick={closeOverlay}
  //         aria-current="false"
  //         className={styles.nav_item}
  //       >
  //         {t('nav.login')}
  //       </Link>
  //     )}
  //   </li>,
  // );

  return (
    <>
      <nav className={styles.navbar} id="nav-bar">
        <button
          type="button"
          aria-controls="mobile-menu"
          aria-label={t(`accessibility.${isOpen ? 'close_menu' : 'open_menu'}`)}
          id="mobile-menu-trigger"
          onClick={() => setIsOpen(!isOpen)}
          className={styles.menu_trigger}
          aria-expanded={isOpen}
        >
          <AccessibilityText>
            {t(`accessibility.${isOpen ? 'close_menu' : 'open_menu'}`)}
          </AccessibilityText>
        </button>
        <Link href="/" className={styles.logo_link}>
          <Image
            src="/logo_no_safety.svg"
            width={56}
            height={29}
            alt="alvar pet"
            priority
          />
        </Link>
        <ul className={styles.link_list_desktop}>{navItems}</ul>
        <span />
      </nav>

      <nav
        id="mobile-menu"
        role="region"
        aria-labelledby="main-menu-trigger"
        tabIndex={isOpen ? 0 : -1}
        className={clsx(styles.mobile_container, {
          [styles.mobile_container_open]: isOpen,
        })}
      >
        <ul className={styles.link_list_mobile}>{navItems}</ul>
        <hr className={styles.divider} />
        <ContactChannels locale={locale} />
      </nav>
    </>
  );
}
