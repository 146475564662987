import(/* webpackMode: "eager" */ "/usr/src/app/ui/navigation/contact-channels.module.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/ui/footer/footer.module.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/[locale]/root-layout.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/ui/navigation/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/usr/src/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/usr/src/styles/fonts.css");
;
import(/* webpackMode: "eager" */ "/usr/src/styles/reset.css");
;
import(/* webpackMode: "eager" */ "/usr/src/styles/globals.css");
